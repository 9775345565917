import * as React from 'react'
import { Container } from '@material-ui/core'

const NotFoundPage: React.FC = () => (
    <Container maxWidth="md">
        <h1>NOT FOUND</h1>
        <p>ページが見つかりませんでした</p>
    </Container>
)

export default NotFoundPage
